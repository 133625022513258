<template>
  <div>
    <el-card style="min-height: 45rem">
      <div>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input v-model="searchForm.phone" placeholder="手机号"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="searchForm.nickname" placeholder="昵称"></el-input>
          </el-col>
          <el-col :span="12">
            <el-button-group>
              <el-button @click="searchGo" type="primary">搜索</el-button>
              <el-button @click="searchReset">重置</el-button>
            </el-button-group>
          </el-col>
        </el-row>
      </div>
      <el-table row-key="id" :data="list">
        <el-table-column prop="user.id" label="编号"></el-table-column>
        <el-table-column  prop="user.nickname" label="昵称" show-overflow-tooltip></el-table-column>
        <el-table-column  prop="user.phone" label="手机号"></el-table-column>
        <el-table-column  prop="area_label" label="代理区域"></el-table-column>
        <el-table-column width="300" label="操作">
          <template #default="s">
            <div class="flex-def flex-zBetween">
              <cashLog :uid="s.row.uid"><el-button type="text">提现记录</el-button></cashLog>
              <setArea @reload="load" :uid="s.row.uid" :mode="2"><el-button type="text">指定代理区域</el-button></setArea>
              <y_member_up_level @reload="load" :disabled_tag="true" :uid="s.row.uid"></y_member_up_level>
            </div>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import cashLog from "@/components/chain/cashLog";
import setArea from "@/components/area_agent/set-area";
import y_member_up_level from "@/components/y_member_up_level";
export default {
  name: "manage",
  components:{cashLog,setArea,y_member_up_level},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm:{
        phone:"",
        nickname:"",
      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    searchGo(){
      this.page = 1;
      this.list.length =0;
      this.load();
    },
    searchReset(){
      this.searchForm = {
        phone:"",
        nickname:"",
      }
      this.searchGo()
    },
    load() {
      this.$api.addons.areaAgent.searchUser({
        page:this.page,
        level:4,
        nickname:this.searchForm.nickname,
        phone:this.searchForm.phone,
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>